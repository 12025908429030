div.stdHelloWorldSimple {
  div.helloworld__message {
    color: darkolivegreen;
    font-variant: contextual;
    font-size: 2rem;
    padding: 2rem;
    background-color: antiquewhite;
    border: 5px solid crimson;

    &::before {
      content: "local style   ";
      color: black;
    }

  }
}
